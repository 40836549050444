







































































































































































































































.el-row {
  margin-bottom: 1%;
}
.el-switch {
  margin-top: 10px;
}
