


























































































































































































































































































































































.manufacturing-job-table {
  word-break: break-word;
}

.rework-name-header {
  min-width: 95px;
  max-width: 95px;
}

.job-step-item {
  border: 0px;
  height: 30px;
}

.job-step-progress-bar,
job-step-planned-time-placeholder {
  padding-right: 0px;
  margin-right: 0px;
}
