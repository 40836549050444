



















































































































































































































































.el-collapse_header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.el-collapse_header-container > .el-collapse-header-bold {
  font-weight: bold;
}

.el-collapse_header-container > .mar-r-5 {
  margin-right: 5px;
}

.el-container-item {
  display: flex;
  justify-content: space-between;
}

.el-container-item > .mar-b-15-l-5 {
  margin: 0 0 15px 5px;
}
