




















































































































































































































































































































































































































































































































.el-dialog__body {
  padding: 0px 20px;
}
.el-table td,
.el-table th {
  padding: 4px 0;
}
