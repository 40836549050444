.el-message {
  top: 80px;
}

.el-menu {
  &--horizontal {
    > .el-menu-item.is-active {
      border-bottom: 0;
    }
    > .el-submenu {
      &.is-active {
        border-bottom: 0;
        .el-submenu {
          &__title {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.el-button {
  + span {
    margin-left: 10px;
  }
  span + & {
    margin-left: 10px;
  }
}

.el-card {
  .el-table {
    & th,
    & td {
      padding: 8px 0;
    }
  }
}

.table-card {
  .el-card__body {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.el-table {
  position: inherit !important;
  &--border,
  &--group,
  &::before {
    background-color: transparent;
  }
  thead {
    color: initial;
    text-transform: uppercase;
  }
  .cell {
    word-break: keep-all;
  }
}

.el-notification {
  position: initial;
  margin-top: 20px;
  margin-right: 20px;

  &__content {
    text-align: left;
  }

  .el-icon {
    height: 24px;
    width: 24px;
    font-size: 24px;
    &-info {
      color: #409eff;
    }
    &-error {
      color: #f56c6c;
    }
    &-warning {
      color: #e6a23c;
    }
  }
}

th {
  font-weight: 600;
}

.el-menu--collapse > .el-menu-item [class^="el-icon"],
.el-menu--collapse > .el-submenu > .el-submenu__title [class^="el-icon"] {
  margin: 0;
  vertical-align: middle;
  width: 24px;
  text-align: center;
}

.el-form--label-top {
  .el-form-item__label {
    padding: initial;
    margin-bottom: initial;
  }
}

.el-dialog {
  &__title {
    border-bottom: 1px solid #4682af;
    padding: 5px;
    text-transform: uppercase;
    font-size: 1.25em;
    font-weight: bold;
    display: block;
    margin: 0 0 10px;
  }
  &__header {
    padding: 5px 5px 0;
  }
  &__footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
  }
}

.el-card__header {
  padding: 8px 8px;
}

.el-select__input {
  vertical-align: baseline;
}

/*.el-loading-parent--relative {
    position: absolute !important
}*/

.el-badge__content.is-fixed {
  position: absolute;
  top: 3px;
  right: 17px;
  -webkit-transform: translateY(-50%) translateX(100%);
  transform: translateY(-50%) translateX(100%);
}

.el-badge__content {
  font-size: 10px;
  z-index: 1;
  line-height: 15px;
}

.notification .el-badge__content {
  background-color: #409eff;
}

.product-type-tabs
  .el-radio-button__orig-radio:checked
  + .el-radio-button__inner {
  background-color: #4682af;
  border-color: #5591be;
}

.product-type-tabs .el-radio-button__inner {
  color: #fff;
  background-color: #5591be;
}

.el-radio-button__inner {
  color: #000;
  background-color: #fff;
}

.el-radio-button__inner:hover {
  color: #a5a5a5;
}

.el-badge__content.is-fixed.is-dot {
  right: 0px;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #4682af;
  background-color: #fff;
  border-right-color: #dcdfe6;
  border-left-color: #dcdfe6;
}

.notifications-menu {
  margin-right: 20px;
}
