
























































































.mar-15 {
  margin: 15px;
}

.drag-wp {
  width: 40px;
  text-align: center;
  padding-top: 10px;
  font-size: 26px;
}
