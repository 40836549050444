

































.el-step__title.is-finish {
  font-weight: 700;
}
