
























































































































































































.readonly-data {
  font-size: 12px;
  padding-left: 15px;
}

.links {
  padding-top: 5px;
  line-height: 18px;
}

h4 {
  padding-left: 20px;
}

.switch {
  margin-left: 14px;
}
