.editable-input input[type="number"] + .editable-clear-x {
  right: 10px !important;
}

.editable-input .input-mini {
  width: 120px;
}

.editable-input .add-on {
  background-color: #eeeeee;
  border: 1px solid #ccc;
  display: inline-block;
  font-weight: normal;
  height: 30px;
  line-height: 18px;
  min-width: 16px;
  padding: 4px 5px;
  text-align: center;
  text-shadow: 0 1px 0 #ffffff;
  vertical-align: middle;
  width: auto;
  margin-bottom: 1px;
}

.editable-inline .add-on .icon-th {
  margin-left: 3px;
  margin-top: 3px;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  cursor: pointer;
  display: block;
  height: 16px;
  width: 16px;
}

.input-append.date input {
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  color: #555;
  font-size: 14px;
  height: 34px;
  line-height: 1.42857;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.input-append.date input {
  border-radius: 3px;
  font-size: 12px;
  height: 30px;
  line-height: 1.5;
  border-right: none;
  width: 130px;
}

.input-append.date input:focus {
  border-color: #66afe9;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(102, 175, 233, 0.6);
  outline: 0 none;
}

.editable-input [class^="icon-"],
.editable-input [class*=" icon-"],
.datepicker [class^="icon-"],
.datepicker [class*=" icon-"] {
  background-image: url("../images/glyphicons-halflings.png");
  background-position: 14px 14px;
  background-repeat: no-repeat;
  display: inline-block;
  height: 14px;
  line-height: 14px;
  vertical-align: text-top;
  width: 14px;
}

.editable-input .add-on .icon-th {
  background-position: -240px 0;
}

.datepicker .icon-arrow-right {
  background-position: -264px -96px;
}

.datepicker .icon-arrow-left {
  background-position: -240px -96px;
}

#period-input {
  background-color: #fff;
  cursor: pointer;
}

.processing-status-list-left {
  float: left;
  margin-right: 5px;
}

.white-space-wrap {
  white-space: pre-wrap;
  word-break: keep-all;
}

.first-row-no-border > td {
  border-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0;
}
