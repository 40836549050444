





















































:root {
  --fc-button-text-color: #333;
  --fc-button-bg-color: #f5f5f5;
  --fc-button-border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1)
    rgba(0, 0, 0, 0.25);
  --fc-button-active-bg-color: #cccccc;
  --fc-button-active-border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1)
    rgba(0, 0, 0, 0.25);
  --fc-button-hover-border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1)
    rgba(0, 0, 0, 0.25);
  --fc-button-hover-bg-color: #cccccc;
}
