





























































































































































































































































.vertical-option {
  display: block;
  line-height: normal;
  margin-right: 50px;
}

.tree-form-item {
  width: 300px;
  margin-left: 20px;
}

.product-select {
  width: 350px;
}
