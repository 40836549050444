

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-aside::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.menuMaxWidth {
  min-width: 250px;
}

.el-menu--popup {
  z-index: 100;
  min-width: 225px;
  border: none;
  padding: 5px;
  border-radius: 2px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.el-submenu__title i {
  color: #fff;
}

$scrollbar-width: 0.5em;
$scrollbar-color: rgba(255, 255, 255, 0.4);
$scrollbar-hover-color: rgba(255, 255, 255, 1);
$scrollbar-radius: 0.5em;
.el-menu--popup {
  max-height: 70vh;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    border-radius: $scrollbar-radius;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $scrollbar-hover-color;
  }

  &::-moz-scrollbar {
    width: $scrollbar-width;
  }

  &::-moz-scrollbar-track {
    background-color: transparent;
  }

  &::-moz-scrollbar-thumb {
    background-color: $scrollbar-color;
    border-radius: $scrollbar-radius;
  }

  &::-moz-scrollbar-thumb:hover {
    background-color: $scrollbar-hover-color;
  }
}
