





























































































































































































































































































.vertical-option {
  display: block;
  line-height: normal;
  margin-right: 50px;
}

.tree-form-item-large {
  min-width: 300px;
  max-width: 700px;
  margin-left: 20px;
}

.document-select {
  width: 350px;
}
