

























































.availability-details .el-row {
  margin-bottom: 10px;
}
