




























































































































.box-card::v-deep > .label-16 {
  font-size: 16px;
  display: inline-block;
}
