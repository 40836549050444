




































































































































































































































































































































































































































































































































.el-form--label-top::v-deep .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0 0;
}

.el-form-item {
  margin-bottom: 0px;
}

.el-cascader-menu__wrap {
  height: 335px;
}

.el-cascader__search-input {
  min-width: 20px;
}
