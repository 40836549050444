



















































































































































































































::v-deep h4 {
  margin: 0px;
}

.grey-info {
  background-color: #ECECEC;
}

.left-col{
  text-align: end;
  padding-top: 5px;
}

.right-col{
  text-align: start;
}

.workflow-modify-button {
  margin-bottom: inherit;
  background-color: #ECECEC;
}
