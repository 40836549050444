































































.heading-bottom-border {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dcdfe6;
}

.card-wp {
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-left: 25px;
}
