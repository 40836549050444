<template>
  <div style="height: 70vh">
    <el-table
      height="100%"
      ref="manufacturingCycleTable"
      :data="manufacturingCycleData"
      :row-class-name="tableRowClassName"
    >
      <el-table-column width="55">
        <template slot-scope="scope">
          <div v-if="canDisplayCheckbox(scope.row)">
            <el-checkbox
              @change="checkboxChange(scope.row)"
              v-model="scope.row.selected"
            >
            </el-checkbox>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="PLAN ID">
        <template slot-scope="scope">
          <div :style="scope.row.style">
            <el-badge
              value="On Hold"
              v-if="scope.row.isOnHold"
              type="warning"
              style="top: 4px"
            ></el-badge>
            <router-link
              :to="
                '/ManufacturingCycle/Details/' +
                scope.row.id +
                '?useContext=true'
              "
              >{{ scope.row.requestId }}</router-link
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="PRODUCT SYMBOL" prop="productSymbol">
      </el-table-column>
      <el-table-column label="DESIGN STATUS" prop="designStatusDescription">
      </el-table-column>
      <el-table-column label="OUT-OF-MANUF.">
        <div slot-scope="scope">
          <template v-if="scope.row.dueDate !== null">
            {{
              scope.row.dueDate
                | momenttz(scope.row.manufacturingLocationTimezone)
            }}
          </template>
          <template v-else>-</template>
          {{
            scope.row.schedulingStrategy !== null
              ? `(${scope.row.schedulingStrategy})`
              : ""
          }}
        </div>
      </el-table-column>
      <el-table-column label="MANUFACTURING PROGRESS">
        <template slot-scope="scope">
          <el-progress
            :text-inside="true"
            :stroke-width="26"
            :percentage="scope.row.manufacturingProgress"
          ></el-progress>
        </template>
      </el-table-column>
      <el-table-column label="COMMENT" prop="lastComment"> </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import { HTTP } from "@/services/HttpCommon";
import DateTime from "@/mixins/DateTime";
import ManufacturingCycleBulkOperationsAffectedCasesModel from "@/models/ManufacturingCycleBulkOperationsAffectedCasesModel";
import { manufacturingCycleBulkOperationsService } from "@/services/manufacturingcyclebulkoperations.service";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { showLoading, hideLoading } from "@/store/mutation-types";
import { FulfilmentPlanStatus } from "@/enums/FulfilmentPlanStatus";

export default {
  mixins: [DateTime],
  async mounted() {
    await this.loadChosenFilters();
    this.getManufacturingCyclesData();
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("manufacturingCycleList", [
      "filters",
      "filtersInitialized",
      "updateSortedColumns",
      "availableColumns",
      "activeColumns",
      "sortedColumns",
      "initSortedColumns",
      "selectedRecordId",
      "page",
      "availableSortColumnsOrder",
      "filtersLoaded",
      "refresh",
    ]),
    ...mapGetters("manufacturingCycleList", ["selectedAdditionalFilters"]),
  },
  watch: {
    refresh: function (newValue): void {
      if (newValue) {
        this.$refs.manufacturingCycleList.getData();
      }
    },
    initSortedColumns: function (): void {
      if (this.initSortedColumns) {
        this.updateColumns();
      }
    },
    updateSortedColumns: function (): void {
      if (this.updateSortedColumns) {
        this.updateColumns();
        this.$refs.manufacturingCycleList.getData();
        this.storeUpdateSortedColumns(false);
      }
    },
    activeColumns: function (): void {
      this.columns = this.activeColumns;
    },
  },
  props: ["selectedManufacturingCycles"],
  data() {
    return {
      manufacturingCycleData: [],
      manufacturingCycleBulkOperationsAffectedCasesModel:
        new ManufacturingCycleBulkOperationsAffectedCasesModel(),
      selectedRows: [],
      columns: [
        "bulkOperationSelection",
        "requestIdWithManufacturingRevision",
        "productSymbol",
        "designStatusDescription",
        "dueDate",
        "manufacturingProgress",
        "lastComment",
      ],
      options: {
        filterable: false,
        sendInitialRequest: false,
        headings: {
          bulkOperationSelection: "",
          requestIdWithManufacturingRevision: "Plan Id",
          productSymbol: "Product Symbol",
          designStatusDescription: "Design Status",
          dueDate: "Out-Of-Manuf.",
          lastComment: "Comment",
          manufacturingProgress: "Manufacturing Progress",
        },
        rowAttributesCallback: (row) => {
          let isSelected = row.id == this.selectedRecordId;
          return isSelected ? { style: "background-color: #E6E6E6" } : {};
        },
        perPage: 20000,
        perPageValues: [10, 20, 50, 100],
        initialPage: Math.ceil(this.$route.query.index / 10) || 1,
        preserveState: true,
        saveState: true,
        storage: "local",
        responseAdapter: function (response) {
          return {
            data: response.data.items,
            count: response.data.filteredPlansCount,
          };
        },
      },
    };
  },
  methods: {
    canDisplayCheckbox(row): boolean {
      var result = false;

      if (this.selectedManufacturingCycles.length == 0 || row.isSuggested) {
        result = true;
      }

      if (
        row.fulfilmentPlanStatus ===
          FulfilmentPlanStatus.ReadyForManufacturing ||
        row.fulfilmentPlanStatus ===
          FulfilmentPlanStatus.ManufacturingApprovalPending ||
        row.fulfilmentPlanStatus === FulfilmentPlanStatus.Manufacturing ||
        row.fulfilmentPlanStatus ===
          FulfilmentPlanStatus.ReadyForManufacturingApprovalPending
      ) {
        result = result && true;
      } else {
        result = false;
      }

      return result;
    },
    checkboxChange(row): void {
      this.showLoading();
      this.manufacturingCycleData.forEach((el) => {
        el.isSuggested = false;
      });
      var selectedRows = this.manufacturingCycleData.filter(
        (el) => el.selected
      );

      this.selectedManufacturingCycles.splice(
        0,
        this.selectedManufacturingCycles.length
      );

      selectedRows.forEach((row) => {
        this.selectedManufacturingCycles.push(row.id);
      });

      this.manufacturingCycleBulkOperationsAffectedCasesModel.selectedCases =
        selectedRows.map((x) => x.id);

      var manufacturingCycleDataLocal = this.manufacturingCycleData;
      manufacturingCycleBulkOperationsService
        .getManufacturingCyclesWithCompatibleWorkflows(
          this.manufacturingCycleBulkOperationsAffectedCasesModel
        )
        .then((response) => {
          for (const dataRowId in manufacturingCycleDataLocal) {
            for (const resultRowId in response.data.result) {
              if (
                manufacturingCycleDataLocal[dataRowId].id ===
                response.data.result[resultRowId]
              ) {
                manufacturingCycleDataLocal[dataRowId].isSuggested = true;
              }
            }
          }
          this.hideLoading();
        });
      this.$refs.manufacturingCycleTable.doLayout();
    },
    getManufacturingCyclesData(): void {
      this.showLoading();
      let filtersLocal = this.filters;
      filtersLocal.sortColumns = [];
      if (filtersLocal.chosenJobSteps) {
        var chosenSteps = [];
        for (let step of filtersLocal.chosenJobSteps) {
          chosenSteps.push({ stepId: step[0] });
        }
        filtersLocal.selectedWorkflowSteps = chosenSteps;
      }

      filtersLocal.sortColumns = this.sortedColumns.map((sortColumn) => {
        const currentSortColumnIndex = this.availableSortColumnsOrder.findIndex(
          (x) => x == sortColumn.column
        );
        return {
          sortedColumn: currentSortColumnIndex,
          sortOrder: sortColumn.ascending ? 1 : 0,
        };
      });

      if (this.initSelectedRecord) {
        this.initSelectedRecord = false;
      } else {
        this.storeSelectedRecordId(null);
      }

      HTTP.post("ManufacturingCycle/GetFilteredManufacturingCycles", {
        model: {
          ...filtersLocal,
          selectedAdditionalFilters: this.selectedAdditionalFilters,
        },
        limit: 1000,
        page: 1,
      }).then((result) => {
        this.refreshed();
        this.hideLoading();
        this.manufacturingCycleData = result.data.items.map((i) => ({
          ...i,
          isSuggested: false,
          selected: false,
        }));
      });
    },
    tableRowClassName({ row, rowIndex }): string {
      if (row.isSuggested) {
        return "warning-row";
      }
      return null;
    },
    ...mapMutations([showLoading, hideLoading]),
    ...mapActions("manufacturingCycleList", [
      "storeSortedColumns",
      "storeSelectedRecordId",
      "storeUpdateSortedColumns",
      "storeInitSortedColumns",
      "storeFiltersInitialized",
      "loadChosenFilters",
      "refreshed",
    ]),
    updateColumns(): void {
      if (this.sortedColumns.length > 0) {
        this.$refs.manufacturingCycleList.orderBy = this.sortedColumns[0];
      } else {
        this.$refs.manufacturingCycleList.orderBy = {
          asceding: true,
          column: false,
        };
      }
      if (this.sortedColumns.length > 1) {
        this.$refs.manufacturingCycleList.userMultiSorting = {};
        this.$refs.manufacturingCycleList.userMultiSorting[
          this.sortedColumns[0].column
        ] = [];
        for (var i = 1; i < this.sortedColumns.length; i++) {
          this.$refs.manufacturingCycleList.userMultiSorting[
            this.sortedColumns[0].column
          ].push(this.sortedColumns[i]);
        }
      }
    },
    onSortColumns() {
      var list = [];
      if (this.$refs.manufacturingCycleList.orderBy) {
        list.push(this.$refs.manufacturingCycleList.orderBy);
      }
      if (
        this.$refs.manufacturingCycleList.userMultiSorting[
          Object.keys(this.$refs.manufacturingCycleList.userMultiSorting)[0]
        ] !== undefined &&
        this.$refs.manufacturingCycleList.userMultiSorting[
          Object.keys(this.$refs.manufacturingCycleList.userMultiSorting)[0]
        ] !== null
      ) {
        for (
          var i = 0;
          i <
          this.$refs.manufacturingCycleList.userMultiSorting[
            Object.keys(this.$refs.manufacturingCycleList.userMultiSorting)[0]
          ].length;
          i++
        ) {
          list.push(
            this.$refs.manufacturingCycleList.userMultiSorting[
              Object.keys(this.$refs.manufacturingCycleList.userMultiSorting)[0]
            ][i]
          );
        }
      }
      this.storeSortedColumns(list);
    },
  },
};
</script>

<style>
.el-table__row.warning-row {
  background: rgb(195, 224, 255);
}
</style>
