$asset-path: "../../images/icons";

.manufacturing-job-table thead th:hover {
  background-color: transparent;
}

.manufacturing-job-table .step-name {
  font-size: 0.7em;
  text-transform: none;
}

.table.manufacturing-job-table {
  table-layout: fixed;
  width: auto;
}

.table.manufacturing-job-table thead > tr:first-of-type {
  border-bottom: 1px solid #4682af;
}

.manufacturing-on-hold-background {
  background: #f5b88c;
}

.table.manufacturing-job-table > tbody > tr > th,
.table.manufacturing-job-table > tfoot > tr > th,
.table.manufacturing-job-table > thead > tr > th {
  border-top: none;
  border-bottom: none;
}

.manufacturing-job-table td,
.manufacturing-job-table th {
  display: table-cell;
  overflow: hidden;
  padding: 0 !important;
  width: 55px;
  min-width: 55px;
  max-width: 55px;
  word-wrap: break-word;
}

.manufacturing-job-table td {
  border: 1px solid #e1e1e1;
  height: 60px;
  font-size: 9px;
  font-weight: 600;
}

.manufacturing-job-table td:first-of-type,
.manufacturing-job-table th:first-of-type {
  width: 80px;
}

.manufacturing-job-table thead th {
  font-weight: normal;
}

.manufacturing-job-table thead th:first-of-type {
  font-weight: 600;
}

tr.separator {
  height: 5px;
}

td.job-step-in-progress {
  background-color: #a0d5ff;
  color: #fff;
}

td.job-step-completed {
  background-color: #e0efac;
  color: #bbcd78;
}

td.job-step-skipped {
  background-color: #b2b2b2;
  color: #6f6f6f;
}

td.job-step-on-hold {
  background-color: #f5b88c;
  color: #fff;
}

td.job-step-critical-1 {
  background-color: rgba(255, 254, 144, 0.5);
  color: #6f6f6f;
}

td.job-step-critical-2 {
  background-color: rgba(255, 206, 40, 0.5);
  color: #fff;
}

td.job-step-critical-3 {
  background-color: rgba(251, 109, 92, 0.5);
  color: #fff;
}

td.job-step-on-hold > div {
  padding-top: 12px;
}

td.job-step-completed > div {
  padding-top: 13px;
}

td.job-step-skipped > div {
  padding-top: 13px;
}

td.job-step-in-progress > div {
  padding-top: 15px;
}

td.job-step-open > div {
  padding-top: 16px;
}

td.job-step-in-progress > div > span {
  background-image: url(#{$asset-path}/icon-manufacturing-job-status-in-progress.png);
  background-repeat: no-repeat;
  display: inline-block;
  height: 11px;
  width: 20px;
}

td.job-step-on-hold > div > span {
  background-image: url(#{$asset-path}/icon-manufacturing-job-status-on-hold.png);
  background-repeat: no-repeat;
  display: inline-block;
  height: 14px;
  width: 14px;
}

td.job-step-completed > div > span {
  background-image: url(#{$asset-path}/icon-manufacturing-job-status-completed.png);
  background-repeat: no-repeat;
  display: inline-block;
  height: 13px;
  width: 13px;
}

td.job-step-skipped > div > span {
  background-image: url(#{$asset-path}/icon-manufacturing-job-status-skipped.png);
  background-repeat: no-repeat;
  display: inline-block;
  height: 13px;
  width: 13px;
}

td.job-step-open > div > span {
  background-image: url(#{$asset-path}/icon-manufacturing-job-status-pending.png);
  background-repeat: no-repeat;
  display: inline-block;
  height: 8px;
  width: 21px;
}

.progress.manufacturing-job-progress {
  height: 58px;
  border: none;
}

.progress.manufacturing-job-progress .progress-bar {
  height: 58px !important;
  margin: 0 !important;
}

.progress.manufacturing-job-progress .progress-bar div:first-child {
  height: 20px;
  margin-top: 10px;
}

.progress.manufacturing-job-progress .progress-bar .steps {
  color: #595959;
  font-size: 18px;
  margin-left: 3px;
  text-align: left;
}

.progress.manufacturing-job-progress .progress-bar .user-symbol {
  padding-right: 1px;
  text-align: right;
  color: #595959;
  font-size: 11px;
}

.progress.manufacturing-job-progress .progress-bar .status {
  margin-left: 18px;
  text-align: left;
  color: #595959;
  font-size: 11px;
}

.progress.manufacturing-job-progress.job-in-process .progress-bar .status {
  margin-left: 5px;
}

.progress.manufacturing-job-progress.job-completed .progress-bar .steps {
  color: #bbcd78;
}

.progress.manufacturing-job-progress.job-completed .progress-bar .user-symbol {
  color: #bbcd78;
}

.progress.manufacturing-job-progress.job-completed .progress-bar .status {
  color: #bbcd78;
}

.progress.manufacturing-job-progress.job-in-process .progress-bar {
  background-color: #a0d5ff;
}

.progress.manufacturing-job-progress.job-completed .progress-bar {
  background-color: #e0efac;
  color: #bbcd78;
}

.progress.manufacturing-job-progress.job-on-hold .progress-bar {
  background-color: #f5b88c;
}

.progress.manufacturing-job-progress.job-terminated .progress-bar {
  background-color: #f5b88c;
}

.progress.manufacturing-job-progress.job-in-process.change-step {
  background-color: #a0d5ff;
}

.progress.manufacturing-job-progress.job-completed.change-step {
  background-color: #e0efac;
  color: #bbcd78;
}

.progress.manufacturing-job-progress.job-on-hold.change-step {
  background-color: #f5b88c;
}

.progress.manufacturing-job-progress.job-terminated.change-step {
  background-color: #f5b88c;
}

.progress.manufacturing-job-progress.job-on-hold .manufacturing-job-icon {
  background-image: url(#{$asset-path}/icon-manufacturing-status-on-hold.png);
  background-repeat: no-repeat;
  height: 9px;
  width: 8px;
  margin-top: 4px;
  margin-left: 6px;
}

.progress.manufacturing-job-progress.job-terminated .manufacturing-job-icon {
  background-image: url(#{$asset-path}/icon-manufacturing-status-terminated.png);
  background-repeat: no-repeat;
  height: 9px;
  width: 12px;
  margin-top: 4px;
  margin-left: 6px;
}

.progress.manufacturing-job-progress.job-completed .manufacturing-job-icon {
  background-image: url(#{$asset-path}/icon-manufacturing-status-completed.png);
  background-repeat: no-repeat;
  height: 12px;
  width: 11px;
  margin-top: 4px;
  margin-left: 6px;
}

.progress.manufacturing-job-progress.job-open .manufacturing-job-icon {
  background-image: url(#{$asset-path}/icon-manufacturing-status-pending.png);
  background-repeat: no-repeat;
  height: 7px;
  width: 15px;
  margin-top: 7px;
  margin-left: 4px;
}

.table.manufacturing-cycle-list-table {
  table-layout: fixed;
  width: 100%;
}

.table.manufacturing-cycle-list-table th {
  font-size: 0.9em;
  height: 35px;
}

.table.manufacturing-cycle-list-table .regular-cell {
  min-width: 80px;
}

.table-hover > tbody > tr.manufacturing-on-hold-background:hover {
  background-color: #d17c4b;
}

.table.manufacturing-cycle-list-table td.regular-cell {
  font-size: 13px;
  font-weight: normal;
}

.table.manufacturing-cycle-list-table .step-name {
  font-size: 0.7em;
  text-transform: none;
  width: 48px;
}

.table.manufacturing-cycle-list-table .command-cell {
  max-width: 40px;
  width: 40px;
}

.table.manufacturing-cycle-list-table .step-content {
  width: 48px;
}

.table.manufacturing-cycle-list-table .deliverable-name,
.table.manufacturing-cycle-list-table .deliverable-content {
  width: 75px;
}

.table.manufacturing-cycle-list-table td,
.table.manufacturing-cycle-list-table th {
  overflow: hidden;
  word-wrap: break-word;
  padding: 0 !important;
}

.table.manufacturing-cycle-list-table td {
  border: 1px solid #e1e1e1;
  font-size: 9px;
  font-weight: 600;
  height: 60px;
}

.table.manufacturing-cycle-list-table td.grid-progress-separator {
  border-left: solid 2px #a5a5a5;
}

.table.manufacturing-cycle-list-table td.job-step-on-hold > div,
.table.manufacturing-cycle-list-table td.job-step-in-progress > div,
.table.manufacturing-cycle-list-table td.job-step-completed > div {
  padding-top: 0;
}

.table.manufacturing-cycle-list-table .step-not-available {
  cursor: not-allowed;
}

.table.manufacturing-cycle-list-table .js-step-active {
  color: #0d638f;
}

.table.manufacturing-cycle-list-table .js-step-active:hover {
  text-decoration: underline;
}

.table.manufacturing-cycle-list-table td.job-step-open > div {
  padding-top: 5px;
}

input.js-start-current-step,
input.js-complete-current-step,
input.js-fail-current-step,
input.js-restart-job,
input.js-undo-job {
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center top;
  color: #6099c2;
  height: 45px;
}

input.js-start-current-step {
  background-image: url(#{$asset-path}/icon-change-status-in-progress.png);
}

input.js-complete-current-step {
  background-image: url(#{$asset-path}/icon-change-status-completed.png);
}

input.js-fail-current-step {
  background-image: url(#{$asset-path}/icon-change-status-on-hold.png);
}

input.js-restart-job {
  background-image: url(#{$asset-path}/icon-restart-manufacturing-job.png);
}

input.js-undo-job {
  background-image: url(#{$asset-path}/undo.png);
  background-size: 45px;
  background-position: 5px -11px;
}

#change-work-order-step-status-modal .modal-dialog {
  width: 340px;
  margin: 10px 0 0;
}

#change-work-order-step-status-modal .modal-footer {
  padding: 10px;
}

#change-work-order-step-status-modal .modal-content {
  border: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}

#change-work-order-step-status-modal .modal-dialog {
  position: relative;
  background: #fff;
  border: 1px solid #fff;
}

#change-work-order-step-status-modal .modal-dialog:after,
#change-work-order-step-status-modal .modal-dialog:before {
  bottom: 100%;
  left: calc(5% + 220px);
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

#change-work-order-step-status-modal .modal-dialog:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

#change-work-order-step-status-modal .modal-dialog:before {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 11px;
  margin-left: -11px;
}

#change-work-order-step-status-modal .modal-content {
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.product-type-tabs {
  margin-top: 2px;
}

.product-type-tabs li {
  padding: 10px 30px;
  color: #fff;
  background-color: #5591be;
  margin-left: 1px;
  cursor: pointer;
}

.product-type-tabs li.active-product-type {
  background-color: #4682af;
}

.navbar-filter-panel.manufacturing-cycle-filters {
  padding-bottom: 0;
}

.navbar-filter-panel.manufacturing-cycle-filters.form-group {
  margin-bottom: 0;
}

.product-type-tabs li.active-product-type:before {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: transparent transparent white;
  border-image: none;
  border-style: solid;
  border-width: 10px;
  content: "";
  display: block;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  top: 60%;
  width: 0;
}
