.el-row {
  margin-bottom: 20px;
}

.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 0;
}

.VueTables__search-field > label {
  margin-right: 5px;
}

.VueTables__limit-field > label {
  margin-right: 5px;
}

.VueTables__row > td {
  max-width: 300px;
  overflow-wrap: break-word;
}

.table-bordered {
  border: 0;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 0;
}
