





















































































































































































































































































































































.card-element {
  text-align: center;
  vertical-align: middle;
  font-size: 9px;
  font-weight: 600;
  width: 120px;
  margin-right: 5px;
  display: inline-block;
}
