









































































































h4 {
  padding-left: 20px;
}

.region {
  font-size: 12px;
  padding-left: 15px;
}

.region button {
  white-space: normal;
  text-align: left;
  word-wrap: break-word;
}
