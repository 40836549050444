








































































h4 {
  padding-left: 20px;
  display: inline-block;
}

.long-text {
  word-wrap: break-word;
}
