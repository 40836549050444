
.el-form--label-top::v-deep .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0 0;
}

.icon-sharing-level {
  float: right;
  color: #8492a6;
  font-size: 13px;
}

.el-button--primary:focus {
  color: #fff;
  background-color: #4682af;
  border-color: #5591be;
}
