





























































































































































.icon-sharing-level {
  margin-left: 5px;
}
