.modal form {
  margin-bottom: 0;
}

.pull-right .dropdown-menu:after {
  left: auto;
  right: 13px;
}

.pull-right .dropdown-menu {
  left: auto;
  right: 0;
}

.table thead th {
  vertical-align: middle;
  padding: 0;
  font-weight: 600;
  font-size: 1.076em;
}

.table thead th a {
  display: block;
  cursor: pointer;
  color: #333333;
  padding: 8px;
}

.table thead th:hover {
  background-color: #f5f5f5;
}

.table thead th a:hover {
  text-decoration: none;
}

.table thead th.centered,
.table td.centered {
  text-align: center;
}

.table.row-select tr:hover td {
  background-color: #eee !important;
  cursor: default;
}

.alert-block p {
  white-space: pre-wrap;
}

ul.typeahead.dropdown-menu {
  z-index: 9999;
}

.clear {
  clear: both;
}

.block {
  display: block;
}

.context {
  margin-top: 2px;
  margin-bottom: 2px;
}

.fleft {
  float: left;
}

.fright {
  float: right;
}

.navbar .container {
  margin-left: 5em;
  margin-right: 5em;
}

.table.table-hover tbody tr:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.table th {
  text-align: center;
}

.table thead th {
  text-transform: uppercase;
}

.table th.mini {
  font-size: 0.75em;
}

.table tbody tr td {
  text-align: center;
  vertical-align: middle;
}

.search {
  position: relative;
  top: 25%;
  margin-right: 20px;
  margin-left: 20px;
}

.sortable-handler:hover {
  // background-color: #5591be;
}

html {
  height: 100%;
}

body {
  font-family: "segoeUI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000;
  background: #ffffff;
  font-size: 13px;
  min-width: inherit;
  height: 100%;
  width: 100%;
}

.container.non-static {
  width: auto;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 2%;
}

.pad-l-20 {
  padding-left: 20px;
}

.mar-t-20 {
  margin-top: 20px;
}

.mar-r-10 {
  margin-right: 10px;
}

.no-margin {
  margin: 0;
}

.mar-r-5 {
  margin-right: 5px;
}

.nowrap {
  white-space: nowrap;
}

.no-padding {
  padding: 0;
}

h1.details-header {
  color: #4883ae;
  font-size: 1.5em;
  font-weight: 600;
  margin-top: 10px 0 0;
}

h3.details {
  border-bottom: 1px solid #4682af;
  font-size: 1.25em;
  font-weight: 600;
  padding-bottom: 15px;
}

label.details {
  font-weight: 600;
}

label.field-label {
  display: block;
  font-weight: 600;
  text-transform: uppercase;
}

.table > thead > tr > th {
  border-bottom: 1px solid #4682af;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border-top: 1px solid #e1e1e1;
}

.progress-bar {
  color: #000;
  height: 16px;
  margin: 1px;
  line-height: 15px;
  background-color: #a5a5a5;
}

.progress-bar.progress-bar-value-left span {
  color: #fff;
  position: relative;
}

.progress {
  position: relative;
  margin-bottom: 0;
  border-radius: 0;
  border: 1px solid #a5a5a5;
  background-color: transparent;
}

.progress span {
  position: absolute;
  display: block;
  width: 100%;
  color: black;
}

.form-group .multiselect-container > li > a > label {
  padding: 3px 20px 3px 10px;
}

.milestones-timeline-table tbody tr {
  height: 60px;
}

.milestones-timeline-table th,
.milestones-timeline-table td {
  text-align: center;
  border: 1px solid #e1e1e1;
  padding: 5px;
}

.milestones-timeline-table thead td {
  font-weight: 600;
  font-size: 12px;
}

.milestones-timeline-table .milestone-kind {
  border: 0;
  padding: 0;
  position: absolute;
  left: 20px;
  top: auto;
}

.milestones-timeline-table tbody .milestone-kind label {
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  width: 60px;
  position: absolute;
}

.milestones-timeline-container {
  overflow: auto;
  direction: rtl;
}

.timeline-weekend {
  background-color: #c5c5c5;
}

th.sortable {
  cursor: pointer;
}

.job-step-definition-body {
  height: 160px;
}

.job-step-definition-name {
  height: 40px;
}

.job-step-definition-successor {
  height: 40px;
  vertical-align: middle;
  text-align: center;
  border: 1px solid transparent;
}

.job-step-definition-successor.success-successor {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.job-step-definition-successor.fail-successor {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.job-step-definition-successor.success-successor.successor-under {
  border: 2px dashed;
}

.job-step-definition-successor.fail-successor.successor-under {
  border: 2px dashed;
}

.sort-work-procedure-handler {
  cursor: move;
  background-color: #ccc;
  width: 40px;
  color: #999;
  /*border: 1px solid #999;*/
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 10px;
  border-top-right-radius: 3px;
}

.navbar-link {
  color: #fff;
  cursor: unset;
  text-decoration: none;
}

.navbar-link:hover {
  color: #fff;
  cursor: unset;
  text-decoration: none;
}

.aside-container {
  min-height: 100vh;
  position: fixed;
  z-index: 100;
  overflow: scroll;
  overflow-x: hidden;
}

.crud-add-button {
  float: right;
  margin-bottom: 15px;
}

.crud-table tr td:last-child {
  width: 150px;
}

.availability-calendar > div[class*="el-col"] {
  border: 1px solid #dcdfe6;
  border-right: 0;
}

.availability-calendar > div[class*="el-col"]:last-child {
  border-right: 1px solid #dcdfe6;
}

.availability-calendar
  > div[class*="el-col"]
  > div[class*="el-row"]:first-child {
  border-bottom: 1px solid #dcdfe6;
  text-align: center;
  margin-bottom: 0px;
}

.availability-calendar .el-date-editor.el-input {
  padding: 5px;
  width: 100%;
}

.availability-calendar .el-row {
  margin-bottom: 0px;
}

.content-center {
  display: flex;
  justify-content: center;
}

.table-icon-info {
  position: relative;
  top: -3px;
  color: #409eff;
}

.table-icon-warning {
  position: relative;
  top: -3px;
  color: #e6a23c;
}

.filter-buttons-margin {
  margin: 12px 0 20px 0;
}

.center-icon {
  margin-top: 10px;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
