$fonts-path: "~@/assets/styles/fonts";

@font-face {
  font-family: "segoeUI";
  src: url("#{$fonts-path}/SEGOEUI.TTF") format("truetype");
}
@font-face {
  font-family: "segoeUI";
  src: url("#{$fonts-path}/SEGOEUIB.TTF") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "segoeUI";
  src: url("#{$fonts-path}/SEGOEUII.TTF") format("truetype");
  font-style: italic;
}
@font-face {
  font-family: "segoeUI";
  src: url("#{$fonts-path}/SEGUISB.TTF") format("truetype");
  font-weight: 600;
}
